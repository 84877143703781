import React, { useState, useEffect } from 'react';
import { Trophy, Users, ChevronDown, ChevronUp, Clock, Shield, UserCircle } from 'lucide-react';
import { golfService } from '../../../api/services';
import LoadingSpinner from '../../ui/state/LoadingSpinner';
import LeaderboardCard from '../shared/LeaderboardCard';

const LeagueLeaderboard = ({ 
  isDeadlinePassed,
  userLeagues: preloadedLeagues = null, 
  leagueLeaderboards: preloadedLeaderboards = null,
  isLoading: preloadedIsLoading = false,
  onRefresh: parentRefresh = null,
  preloadedData = false 
}) => {
  const [userLeagues, setUserLeagues] = useState(preloadedLeagues || []);
  const [leagueLeaderboards, setLeagueLeaderboards] = useState(preloadedLeaderboards || {});
  const [loading, setLoading] = useState(preloadedIsLoading);
  const [error, setError] = useState(null);
  const [expandedLeagues, setExpandedLeagues] = useState({});
 
  const loadLeagueData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      // If parent component has a refresh function, use it
      if (parentRefresh && preloadedData) {
        await parentRefresh();
        setLoading(false);
        return;
      }
      
      // Otherwise, load data directly in this component
      const leaguesResponse = await golfService.getAvailableLeagues();
      
      if (!leaguesResponse?.leagues || !Array.isArray(leaguesResponse.leagues)) {
        console.log('No leagues found or invalid response format');
        setUserLeagues([]);
        setLeagueLeaderboards({});
        setLoading(false);
        return;
      }
   
     // Filter to find leagues where the current user is a member
// Make sure to handle potentially null or invalid members arrays
const myLeagues = leaguesResponse.leagues.filter(league => {
  if (!league || !league.members || !Array.isArray(league.members)) {
    return false;
  }
  
  return league.members.some(member => {
    if (!member || !member.user) return false;
    
    // Handle both string IDs and object IDs
    const memberId = typeof member.user === 'string' 
      ? member.user 
      : (member.user._id || member.user);
      
    return memberId === leaguesResponse.currentUserId;
  });
});

// Initialize all leagues as expanded
const initialExpandedState = {};
myLeagues.forEach(league => {
  initialExpandedState[league._id] = true;
});
setExpandedLeagues(initialExpandedState);

setUserLeagues(myLeagues);
   
      const leaderboards = {};
      // Only load leaderboard data if deadline has passed
      if (isDeadlinePassed) {
        for (const league of myLeagues) {
          try {
            if (!league || !league._id) {
              console.warn('Skipping invalid league entry');
              continue;
            }
            
            const leaderboardResponse = await golfService.getLeagueLeaderboard(league._id);
            // Handle different response formats
            leaderboards[league._id] = leaderboardResponse?.data || 
              (Array.isArray(leaderboardResponse) ? leaderboardResponse : []);
          } catch (err) {
            console.error(`Error loading leaderboard for league ${league.name || 'unknown'}:`, err);
          }
        }
      }
      
      setLeagueLeaderboards(leaderboards);
    } catch (err) {
      console.error('Error loading league data:', err);
      setError('Failed to load league data');
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    // Initialize expanded state for preloaded leagues
    if (preloadedLeagues && preloadedLeagues.length > 0) {
      const initialExpandedState = {};
      preloadedLeagues.forEach(league => {
        initialExpandedState[league._id] = true;
      });
      setExpandedLeagues(initialExpandedState);
    }
    
    // Only load data if not using preloaded data
    if (!preloadedData) {
      loadLeagueData();
    }
  }, [preloadedData, preloadedLeagues]);
  
  // Update state when preloaded data changes
  useEffect(() => {
    if (preloadedData) {
      setUserLeagues(preloadedLeagues || []);
      setLeagueLeaderboards(preloadedLeaderboards || {});
      setLoading(preloadedIsLoading);
    }
  }, [preloadedData, preloadedLeagues, preloadedLeaderboards, preloadedIsLoading]);

  const handleRefresh = async () => {
    // If parent component has a refresh function, use it
    if (parentRefresh && preloadedData) {
      await parentRefresh();
    } else {
      await loadLeagueData();
    }
  };

  // Toggle a league's expanded/collapsed state
  const toggleLeagueExpanded = (leagueId) => {
    setExpandedLeagues(prev => ({
      ...prev,
      [leagueId]: !prev[leagueId]
    }));
  };
 
  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <LoadingSpinner />
      </div>
    );
  }
 
  if (error) {
    return (
      <LeaderboardCard title="My Leagues" icon={Users}>
        <div className="p-4 text-red-600">{error}</div>
      </LeaderboardCard>
    );
  }
 
  if (userLeagues.length === 0) {
    return (
      <LeaderboardCard title="My Leagues" icon={Users}>
        <div className="p-4">
          <p className="text-gray-600">
            You haven't joined any leagues yet. Visit the My Selections tab to join a league.
          </p>
        </div>
      </LeaderboardCard>
    );
  }
 
  return (
    <div className="space-y-8">
      {userLeagues.map(league => {
        const leaderboardData = leagueLeaderboards[league._id] || [];
        const isExpanded = expandedLeagues[league._id];
        const leagueMemberCount = league.members?.length || 0;

        return (
          <div key={league._id} className="border border-gray-200 rounded-lg overflow-hidden shadow-sm">
            {/* League header with expand/collapse button */}
            <div 
              className="bg-blue-600 px-4 py-3 flex justify-between items-center cursor-pointer"
              onClick={() => toggleLeagueExpanded(league._id)}
            >
              <div className="flex items-center space-x-3">
                <Trophy className="w-6 h-6 text-white" />
                <h2 className="text-lg font-bold text-white">{league.name}</h2>
                <div className="bg-blue-500 rounded-full px-2 py-0.5 text-xs text-white">
                  {leagueMemberCount} {leagueMemberCount === 1 ? 'member' : 'members'}
                </div>
              </div>
              <button className="text-white p-1 rounded hover:bg-blue-700">
                {isExpanded ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
              </button>
            </div>
            
            {/* League content (only shown if expanded) */}
            {isExpanded && (
              <div className="bg-gradient-to-br from-white to-blue-50">
                {/* Pre-deadline view with members */}
                {!isDeadlinePassed ? (
                  <div className="p-6">
                    <div className="mb-4 flex items-center gap-2 text-blue-600 bg-blue-50 p-3 rounded-lg">
                      <Clock className="w-5 h-5 text-blue-500" />
                      <p className="font-medium">
                        Selections and leaderboard will be visible after the tournament begins
                      </p>
                    </div>
                    
                    {/* League members section */}
                    <div className="mt-4">
                      <h3 className="text-lg font-medium text-gray-900 mb-3 flex items-center">
                        <Users className="w-5 h-5 mr-2 text-blue-500" />
                        League Members
                      </h3>
                      <div className="bg-white rounded-lg border border-gray-100 overflow-hidden">
                        {league.members && league.members.length > 0 ? (
                          <div className="divide-y divide-gray-100">
                            {league.members.map((member, idx) => (
                              <div key={idx} className="p-3 flex items-center justify-between">
                                <div className="flex items-center gap-3">
                                  <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                                    <UserCircle className="w-5 h-5 text-blue-600" />
                                  </div>
                                  <span className="font-medium">{member.displayName}</span>
                                </div>
                                <span className="text-xs bg-gray-100 px-2 py-1 rounded-full text-gray-600">
                                  Joined {new Date(member.joinedAt).toLocaleDateString()}
                                </span>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div className="p-4 text-center text-gray-500">
                            No members have joined this league yet
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  // Post-deadline view with leaderboard
                  <>
                    {leaderboardData.length === 0 ? (
                      <div className="p-6 text-center text-gray-600">
                        No data available for this league yet.
                      </div>
                    ) : (
                      <>
                        {/* Desktop View */}
                        <div className="hidden md:block">
                          <table className="w-full">
                            <thead>
                              <tr className="bg-blue-500/5">
                                <th className="px-4 py-3 text-left w-12 text-sm font-semibold text-blue-700">POS</th>
                                <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">PLAYER</th>
                                <th className="px-4 py-3 text-left text-sm font-semibold text-blue-700">SELECTIONS</th>
                                <th className="px-4 py-3 text-right w-24 text-sm font-semibold text-blue-700">SCORE</th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-100">
                              {leaderboardData.map((entry, index) => (
                                <tr key={index} className="hover:bg-blue-50/50">
                                  <td className="px-4 py-3">
                                    <span className="text-blue-600 font-semibold text-lg">{index + 1}</span>
                                  </td>
                                  <td className="px-4 py-3">
                                    <span className="font-medium text-gray-900">{entry.displayName}</span>
                                  </td>
                                  <td className="px-4 py-3">
                                    <div className="space-y-1.5">
                                      {entry.selections && entry.selections.map((selection, i) => (
                                        <div key={i} className="flex justify-between text-sm max-w-md px-2 py-1">
                                          <span className="text-gray-600">{selection.playerName}</span>
                                          <span className={`font-medium ${
                                            selection.score === "CUT" ? "text-red-500" :
                                            selection.score && selection.score.startsWith("-") ? "text-green-600" : "text-red-600"
                                          }`}>
                                            {selection.score}
                                          </span>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                  <td className="px-4 py-3 text-right">
                                    <span className={`text-xl font-bold ${
                                      entry.totalScore < 0 ? "text-green-600" :
                                      entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
                                    }`}>
                                      {entry.totalScore}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        {/* Mobile View */}
                        <div className="md:hidden divide-y divide-gray-100">
                          {leaderboardData.map((entry, index) => (
                            <div key={index} className="p-4 hover:bg-blue-50/50 transition-colors">
                              <div className="flex items-center justify-between mb-3">
                                <div className="flex items-center gap-3">
                                  <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-100 text-blue-600 font-semibold">
                                    {index + 1}
                                  </span>
                                  <span className="font-medium text-gray-900">{entry.displayName}</span>
                                </div>
                                <span className={`text-lg font-bold ${
                                  entry.totalScore < 0 ? "text-green-600" :
                                  entry.totalScore > 0 ? "text-red-600" : "text-gray-600"
                                }`}>
                                  {entry.totalScore}
                                </span>
                              </div>
                              <div className="space-y-2 bg-gray-50 rounded-lg p-3">
                                {entry.selections && entry.selections.map((selection, i) => (
                                  <div key={i} className="flex justify-between items-center">
                                    <span className="text-sm text-gray-600">{selection.playerName}</span>
                                    <span className={`font-medium ${
                                      selection.score === "CUT" ? "text-red-500" :
                                      selection.score && selection.score.startsWith("-") ? "text-green-600" : "text-red-600"
                                    }`}>
                                      {selection.score}
                                    </span>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            )}
            
            {/* Refresh button */}
            <div className="bg-gray-50 px-4 py-2 flex justify-end">
              <button 
                onClick={(e) => {
                  e.stopPropagation(); // Prevent toggling when clicking refresh
                  handleRefresh();
                }}
                className="text-blue-600 hover:text-blue-800 flex items-center text-sm font-medium"
              >
                <svg 
                  className="w-4 h-4 mr-1" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth="2" 
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                  />
                </svg>
                Refresh
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};
 
export default LeagueLeaderboard;