import BaseApi, { API_ENDPOINTS } from '../../base/baseApi';

class GolfService extends BaseApi {
  constructor() {
    super();
    console.log('GolfService initialized', {
      baseURL: this.baseURL,
      publicEndpoints: this.publicEndpoints
    });
  }

  formatScore(score) {
    if (!score || score === '-') return '-';
    if (score === 'E') return 'E';
    if (score === 'CUT') return 'CUT';
    if (score === 'WD') return 'WD';
    
    const numScore = parseInt(score);
    if (isNaN(numScore)) return score;
    return numScore > 0 ? `+${numScore}` : numScore.toString();
  }

  formatPosition(pos) {
    if (!pos || pos === '-') return '-';
    return pos;
  }

  getUpdateTime(lastUpdated) {
    if (!lastUpdated) return 'Never';
    const date = new Date(lastUpdated);
    const now = new Date();
    const diff = Math.floor((now - date) / 1000);

    if (diff < 60) return 'Just now';
    if (diff < 3600) return `${Math.floor(diff / 60)}m ago`;
    if (diff < 86400) return `${Math.floor(diff / 3600)}h ago`;
    return date.toLocaleDateString();
  }

  async getCurrentGame() {
    try {
      console.log('Fetching current game...');
      const response = await this.get(API_ENDPOINTS.golf.currentGame);
      console.log('Current game response:', response);
      return response;
    } catch (error) {
      console.error('Error getting current game:', error);
      if (error.message === 'No auth token provided') {
        // Try to get public data instead
        return this.getPublicGameInfo();
      }
      throw error;
    }
  }

  async getPublicGameInfo() {
    try {
      console.log('Calling getPublicGameInfo with endpoint:', API_ENDPOINTS.golf.currentGameInfo);
      console.log('BaseURL:', this.baseURL);
      console.log('Full URL will be:', `${this.baseURL}${API_ENDPOINTS.golf.currentGameInfo}`);
      
      const response = await this.getPublic(API_ENDPOINTS.golf.currentGameInfo);
      console.log('Public game info raw response:', response);
      
      // If we get a null response, return default values
      if (!response) {
        console.log('No response from API, returning defaults');
        return {
          name: null,
          tournamentName: null,
          deadline: null,
          status: 'inactive'
        };
      }
      
      console.log('Returning response:', response);
      return response;
    } catch (error) {
      console.error('Error in getPublicGameInfo:', error);
      // Return default values on error
      return {
        name: null,
        tournamentName: null,
        deadline: null,
        status: 'error'
      };
    }
  }

  async getTournamentLeaderboard() {
    try {
      console.log('Fetching tournament leaderboard...');
      const response = await this.getPublic(API_ENDPOINTS.golf.tournament);
      console.log('Tournament leaderboard response:', response);
      
      if (!response) return [];
      
      const leaderboardData = response.data || response;
      console.log('Processed leaderboard data:', leaderboardData);
      
      return Array.isArray(leaderboardData) ? leaderboardData : [];
    } catch (error) {
      console.error('Error getting tournament leaderboard:', error);
      return [];
    }
  }

  async getPlayers() {
    try {
      console.log('Fetching available players...');
      const response = await this.getPublic(API_ENDPOINTS.golf.players);
      console.log('Players response:', response);
      return Array.isArray(response) ? response : [];
    } catch (error) {
      console.error('Error getting players:', error);
      return [];
    }
  }

  async getMySelections() {
    try {
      const response = await this.get(API_ENDPOINTS.golf.mySelections);
      
      if (!response) {
        return { selections: [] };
      }

      if (response.selections) {
        response.selections = response.selections.map(player => ({
          ...player,
          formattedScore: this.formatScore(player.tournamentScore),
          formattedPosition: this.formatPosition(player.tournamentPosition)
        }));
      } else {
        response.selections = [];
      }
      
      return response;
    } catch (error) {
      console.error('Error getting my selections:', error);
      return { selections: [] };
    }
  }

  async submitSelections(selections) {
    try {
      console.log('Submit selections received:', selections);
      
      const playerIds = Array.isArray(selections) 
        ? selections.every(p => typeof p === 'string') 
          ? selections 
          : selections.map(player => player._id)
        : [];
  
      if (!playerIds.length === 3) {
        throw new Error('Must select exactly 3 players');
      }
  
      if (!playerIds.every(id => typeof id === 'string' && id.length > 0)) {
        throw new Error('Invalid player data');
      }
  
      console.log('Sending player IDs:', playerIds);
      
      const response = await this.post(API_ENDPOINTS.golf.selections, { 
        players: playerIds 
      });
      
      return response;
    } catch (error) {
      console.error('Error in submitSelections:', error);
      throw error;
    }
  }

  async getGameLeaderboard() {
    try {
      console.log('Fetching game leaderboard...');
      const response = await this.get(API_ENDPOINTS.golf.gameLeaderboard);
      return Array.isArray(response) ? response : [];
    } catch (error) {
      console.error('Error getting game leaderboard:', error);
      return [];
    }
  }

  getLastUpdate() {
    return this.lastUpdate ? this.getUpdateTime(this.lastUpdate) : 'Never';
  }

  async getAvailableLeagues() {
    try {
      console.log('Fetching available leagues...');
      const response = await this.get(API_ENDPOINTS.golf.leagues);
      console.log('Full leagues response:', response);
      
      // If we get an error response
      if (response && response.status === 'error') {
        console.error('API returned error:', response.message);
        return { leagues: [], currentUserId: null };
      }
      
      // Ensure we have valid data
      let leagues = [];
      
      if (Array.isArray(response)) {
        console.log('Response is an array with', response.length, 'items');
        leagues = response.filter(l => l && l._id);
      } else if (response && response.leagues && Array.isArray(response.leagues)) {
        console.log('Response has leagues array with', response.leagues.length, 'items');
        leagues = response.leagues.filter(l => l && l._id);
      } else {
        console.log('Response format is unexpected:', typeof response);
      }
      
      // Ensure each league has a valid members array
      leagues = leagues.map(league => {
        if (!league.members || !Array.isArray(league.members)) {
          console.log(`League ${league._id || 'unknown'} missing members array`);
          return {...league, members: []};
        }
        
        // Ensure all members have valid user properties
        const validMembers = league.members.filter(member => 
          member && member.user && 
          (typeof member.user === 'string' || member.user._id)
        );
        
        if (validMembers.length !== league.members.length) {
          console.log(`Filtered ${league.members.length - validMembers.length} invalid members from league ${league._id}`);
          return {...league, members: validMembers};
        }
        
        return league;
      });
      
      console.log('Final processed leagues:', leagues.length);
      
      return {
        leagues: leagues,
        currentUserId: response && response.currentUserId ? response.currentUserId : null
      };
    } catch (error) {
      console.error('Error getting available leagues:', error);
      return { leagues: [], currentUserId: null };
    }
  }

  async joinLeague(leagueId) {
    try {
      console.log('Joining league:', leagueId);
      return await this.post(`${API_ENDPOINTS.golf.leagues}/${leagueId}/join`);
    } catch (error) {
      console.error('Error joining league:', error);
      throw error;
    }
  }

  async getLeagueLeaderboard(leagueId) {
    try {
      console.log('Fetching leaderboard for league:', leagueId);
      const response = await this.get(`${API_ENDPOINTS.golf.leagues}/${leagueId}/leaderboard`);
      console.log('League leaderboard raw response:', response);
      
      // Check for the success/data structure first
      if (response && response.success && response.data) {
        console.log('Found data property in successful response');
        return response.data;
      } 
      // Then check if it's already an array
      else if (Array.isArray(response)) {
        console.log('Response is already an array');
        return response;
      } 
      // Finally check for other structures
      else if (response && typeof response === 'object') {
        // Try to find any array in the response object
        const possibleArrays = Object.values(response).filter(val => Array.isArray(val));
        if (possibleArrays.length > 0) {
          console.log('Found array in response object');
          return possibleArrays[0];
        }
      }
      
      console.log('No valid data found in response, returning empty array');
      return [];
    } catch (error) {
      console.error('Error getting league leaderboard:', error);
      return [];
    }
  }

  async leaveLeague(leagueId) {
    try {
      console.log('Leaving league:', leagueId);
      return await this.post(`${API_ENDPOINTS.golf.leagues}/${leagueId}/leave`);
    } catch (error) {
      console.error('Error leaving league:', error);
      throw error;
    }
  }
}

export const golfService = new GolfService();
export { GolfService };