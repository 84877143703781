import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, Edit, Users, Clock, Trophy, RefreshCcw, CheckCircle, UserCircle } from 'lucide-react';
import { useAuth } from '../../AuthContext';
import { LoadingSpinner, ErrorMessage, EmptyState, StateMessage } from '../../components/ui/state';
import { 
  getCurrentGame,
  getTournamentLeaderboard,
  getPlayers,
  getPublicGameInfo
} from '../../api/golf';
import { golfService } from '../../api/services/game/golfService';
import TournamentLeaderboard from '../../components/golf/TournamentLeaderboard';
import PlayerSelectionGrid from '../../components/golf/PlayerSelectionGrid';
import GameLeaderboard from '../../components/golf/GameLeaderboard';
import LeagueEnrollment from '../../components/golf/LeagueEnrollment';
import LeagueLeaderboard from '../../components/golf/LeagueLeaderboard';
import LoginPrompt from '../../components/common/LoginPrompt';  

const GolfTournamentPage = () => {
  console.log('=== GolfTournamentPage Starting ===');
  const { user } = useAuth();
  console.log('Initial auth state:', { hasUser: !!user });

  const [currentGame, setCurrentGame] = useState(null);
  const [leaderboard, setLeaderboard] = useState([]);
  const [lastUpdate, setLastUpdate] = useState('Never');
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [userSelections, setUserSelections] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isLoadingSelections, setIsLoadingSelections] = useState(false);
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);
  const [gameLeaderboard, setGameLeaderboard] = useState([]);
  const [userLeagues, setUserLeagues] = useState([]);
  const [leagueLeaderboards, setLeagueLeaderboards] = useState({});
  const [isLoadingLeagues, setIsLoadingLeagues] = useState(false);
  const [isTournamentDataLoaded, setIsTournamentDataLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState(() => {
    console.log('Initializing activeTab');
    if (!user) return 'selections';
    return isDeadlinePassed ? 'myleagues' : 'selections';
  });

  const loadData = useCallback(async () => {
    console.log('=== loadData called ===');
    try {
      setIsLoading(true);
      setError(null);
      
      if (!user) {
        // Public data loading (keeps existing code)
        try {
          console.log('Attempting to load public data...');
          const publicData = await getPublicGameInfo();
          // ... rest of your existing public data loading ...
        } catch (error) {
          console.error('Error loading public data:', error);
        } finally {
          setIsLoading(false);
        }
        return;
      }
      
      // Step 1: Get basic game info and determine deadline status
      console.log('Loading basic game data...');
      const gameData = await getCurrentGame();
      setCurrentGame(gameData);
      
      if (gameData) {
        // Check deadline status
        const now = new Date();
        const deadline = new Date(gameData.deadline);
        const deadlinePassed = now > deadline || gameData.status === 'active';
        setIsDeadlinePassed(deadlinePassed);
        
        // IMPORTANT: Update the active tab based on deadline status
        // This ensures the correct tab is selected after data loads
        const initialTab = deadlinePassed ? 'myleagues' : 'selections';
        setActiveTab(initialTab);
        
        // Step 2: Load data for initial tab (either My Selections or Leagues)
        if (initialTab === 'selections') {
          // Load selections first
          console.log('Loading selections data first (pre-deadline)...');
          const [playersData, userSelectionsData] = await Promise.all([
            getPlayers(),
            golfService.getMySelections()
          ]);
          
          setAvailablePlayers(playersData || []);
          setUserSelections(userSelectionsData?.selections?.length > 0 ? userSelectionsData : null);
          setIsLoading(false);
          
          // Load leagues in background (lower priority pre-deadline)
          loadLeagueData();
        } else {
          // Load leagues first
          console.log('Loading leagues data first (post-deadline)...');
          await loadLeagueData();
          setIsLoading(false);
          
          // Load selections in background (lower priority post-deadline)
          getPlayers().then(data => setAvailablePlayers(data || []));
          golfService.getMySelections().then(data => {
            setUserSelections(data?.selections?.length > 0 ? data : null);
          });
        }
        
        // Load game leaderboard in background (always lower priority)
        if (deadlinePassed) {
          setTimeout(() => {
            console.log('Loading game leaderboard in background...');
            golfService.getGameLeaderboard()
              .then(data => setGameLeaderboard(data || []))
              .catch(err => console.error('Failed to load game leaderboard:', err));
          }, 100);
        }
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      console.error('Error in loadData:', err);
      setError('Unable to load tournament data');
      setIsLoading(false);
    }
  }, [user]);

  // Ensure loadData is called on mount
  useEffect(() => {
    console.log('=== Mount effect running ===');
    loadData();
  }, [loadData]);
  
  useEffect(() => {
    console.log('activeTab changed to:', activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (currentGame?.deadline) {
      const checkDeadline = () => {
        const isPassed = new Date() > new Date(currentGame.deadline);
        setIsDeadlinePassed(isPassed);
        
        if (isPassed) {
          golfService.getGameLeaderboard()
            .then(data => setGameLeaderboard(data || []))
            .catch(err => console.error('Error fetching game leaderboard:', err));
        }
      };
  
      checkDeadline();
      const interval = setInterval(checkDeadline, 60000);
      return () => clearInterval(interval);
    }
  }, [currentGame]);
  
  useEffect(() => {
    console.log('Selected Players State:', {
      selectedPlayers,
      hasIds: selectedPlayers?.every(p => p?._id),
      length: selectedPlayers?.length
    });
  }, [selectedPlayers]);
  
  useEffect(() => {
    if (activeTab === 'tournament') {
      const tournamentPolling = setInterval(async () => {
        try {
          console.log('Polling tournament leaderboard...');
          const leaderboardData = await golfService.getTournamentLeaderboard();
          console.log('Updated tournament data:', {
            dataReceived: !!leaderboardData,
            playerCount: leaderboardData?.length || 0
          });
          
          const playersArray = Array.isArray(leaderboardData) ? 
            leaderboardData : leaderboardData?.players || [];
          setLeaderboard(playersArray);
          setLastUpdate(new Date().toISOString());
        } catch (err) {
          console.error('Error polling tournament data:', err);
        }
      }, 60000);
  
      return () => clearInterval(tournamentPolling);
    }
  }, [activeTab]);

  useEffect(() => {
    // Clear success message when tab changes
    setSuccessMessage(null);
  }, [activeTab]);

  useEffect(() => {
    if (activeTab === 'tournament' && !isTournamentDataLoaded) {
      loadTournamentData();
    }
  }, [activeTab, isTournamentDataLoaded]);

  useEffect(() => {
    // Skip if no user or game
    if (!user || !currentGame) return;
    
    console.log(`Tab changed to: ${activeTab}, checking if data needs to be loaded`);
    
    if (activeTab === 'myleagues') {
      loadLeagueData();
    } else if (activeTab === 'leaderboard') {
      loadGameLeaderboard();
    } else if (activeTab === 'tournament') {
      loadTournamentData();
    }
  }, [activeTab, user, currentGame]);

  const loadLeagueData = async () => {
    // Skip if we already have league data
    if (userLeagues.length > 0 && Object.keys(leagueLeaderboards).length > 0) {
      console.log('League data already loaded, skipping');
      return;
    }
    
    console.log('Loading league data on demand...');
    setIsLoadingLeagues(true);
    try {
      const leaguesResponse = await golfService.getAvailableLeagues();
      
      if (!leaguesResponse?.leagues) {
        throw new Error('Invalid leagues response');
      }
   
      const myLeagues = leaguesResponse.leagues.filter(league => 
        league.members.some(member => member.user === leaguesResponse.currentUserId)
      );
      
      console.log(`Found ${myLeagues.length} leagues for current user`);
      setUserLeagues(myLeagues);
   
      const leaderboards = {};
      for (const league of myLeagues) {
        try {
          console.log(`Loading leaderboard for league: ${league.name}`);
          const leaderboardResponse = await golfService.getLeagueLeaderboard(league._id);
          // Handle different response formats
          leaderboards[league._id] = leaderboardResponse?.data || 
            (Array.isArray(leaderboardResponse) ? leaderboardResponse : []);
        } catch (err) {
          console.error(`Error loading leaderboard for league ${league.name}:`, err);
        }
      }
      
      console.log('All league leaderboards loaded');
      setLeagueLeaderboards(leaderboards);
    } catch (err) {
      console.error('Error loading league data:', err);
      setError('Failed to load league data');
    } finally {
      setIsLoadingLeagues(false);
    }
  };

  const loadGameLeaderboard = async () => {
    // Skip if we already have game leaderboard data
    if (gameLeaderboard.length > 0) {
      console.log('Game leaderboard already loaded, skipping');
      return;
    }
    
    console.log('Loading game leaderboard on demand...');
    setIsLoading(true);
    try {
      const gameLeaderboardData = await golfService.getGameLeaderboard();
      setGameLeaderboard(gameLeaderboardData || []);
    } catch (err) {
      console.error('Error loading game leaderboard:', err);
      setError('Failed to load game leaderboard');
    } finally {
      setIsLoading(false);
    }
  };
  
  const loadTournamentData = async () => {
    // Skip if we already have tournament data
    if (leaderboard.length > 0) {
      console.log('Tournament data already loaded, skipping');
      return;
    }
    
    console.log('Loading tournament leaderboard on demand...');
    setIsLoading(true);
    try {
      const leaderboardData = await getTournamentLeaderboard();
      
      const playersArray = Array.isArray(leaderboardData) ? 
        leaderboardData : leaderboardData?.players || [];
      setLeaderboard(playersArray);
      setLastUpdate(leaderboardData?.lastUpdated || new Date().toISOString());
    } catch (err) {
      console.error('Error loading tournament data:', err);
      setError('Failed to load tournament leaderboard');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSubmit = async (selectedPlayers) => {
    try {
      console.log('Submitting selections:', selectedPlayers);
      setIsLoadingSelections(true);
  
      if (!user) {
        setError(null);
        setSuccessMessage(null);
        // Don't attempt submission for non-logged in users
        return;
      }
      
      if (!selectedPlayers || selectedPlayers.length !== 3) {
        throw new Error('Please select exactly 3 players');
      }
  
      if (!selectedPlayers.every(p => p && p._id)) {
        throw new Error('Invalid player data');
      }
  
      const playerIds = selectedPlayers.map(player => player._id);
      console.log('Player IDs to submit:', playerIds);
      
      await golfService.submitSelections(playerIds);
      
      const updatedSelections = await golfService.getMySelections();
      console.log('Got updated selections:', updatedSelections);
      
      setUserSelections(updatedSelections);
      setSuccessMessage('Your selections have been submitted successfully!');
      setIsEditing(false);
      setError(null);
      
      setSelectedPlayers([]);
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      setError(err.message);
    } finally {
      setIsLoadingSelections(false);
    }
  };
  
  const handleEdit = () => {
    setIsEditing(true);
    setSuccessMessage(null);
    setError(null);
    
    if (userSelections?.selections) {
      console.log('Editing selections:', userSelections.selections);
      const currentSelections = userSelections.selections.map(selection => {
        const fullPlayer = availablePlayers.find(p => p._id === selection._id);
        return fullPlayer;
      }).filter(Boolean);
      
      console.log('Setting selected players for edit:', currentSelections);
      setSelectedPlayers(currentSelections);
    }
  };

  const renderHeader = () => (
    <div className="bg-blue-600 px-4 py-3">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col gap-2">
          <div>
            <h1 className="text-lg sm:text-xl font-bold text-white">{currentGame?.tournamentName}</h1>
          </div>
          <div className="flex items-center text-sm text-white/90">
            <span className="flex items-center">
              <Calendar className="w-3.5 h-3.5 mr-1" />
              {currentGame?.deadline && new Date(currentGame.deadline).toLocaleString(undefined, {
                day: 'numeric',
                month: 'short',
                hour: '2-digit',
                minute: '2-digit'
              })}
            </span>
            {/* Status and player count only shown on desktop */}
            {user && (
              <div className="hidden sm:flex items-center">
                <span className="text-white/60 mx-2">•</span>
                <span>{currentGame?.status}</span>
                <span className="text-white/60 mx-2">•</span>
                <span className="flex items-center">
                  <Users className="w-3.5 h-3.5 mr-1" />
                  {leaderboard.length} Players
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className="border-b border-gray-200">
      <div className="max-w-4xl mx-auto px-4">
        <nav className="flex -mb-px space-x-8 overflow-x-auto" aria-label="Tabs">
          <button
            onClick={() => setActiveTab('selections')}
            className={`py-4 px-1 border-b-2 font-medium text-sm
              ${activeTab === 'selections'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
          >
            <span className="hidden sm:inline">{user ? 'My Selections' : 'Make Selections'}</span>
            <span className="sm:hidden">Picks</span>
          </button>
          
          <button
            onClick={() => setActiveTab('myleagues')}
            className={`py-4 px-1 border-b-2 font-medium text-sm
              ${activeTab === 'myleagues'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
          >
            <span className="hidden sm:inline">My Leagues</span>
            <span className="sm:hidden">Leagues</span>
          </button>
          
          <button
            onClick={() => setActiveTab('leaderboard')}
            className={`py-4 px-1 border-b-2 font-medium text-sm
              ${activeTab === 'leaderboard'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
          >
            <span className="hidden sm:inline">Game Leaderboard</span>
            <span className="sm:hidden">Overall</span>
          </button>
          
          <button
            onClick={() => setActiveTab('tournament')}
            className={`py-4 px-1 border-b-2 font-medium text-sm
              ${activeTab === 'tournament'
                ? 'border-blue-500 text-blue-600'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
          >
            <span className="hidden sm:inline">Tournament Leaderboard</span>
            <span className="sm:hidden">Tournament</span>
          </button>
        </nav>
      </div>
    </div>
  );

  const renderTournamentLeaderboard = () => {
    console.log('Rendering tournament leaderboard:', { 
      leaderboard, 
      lastUpdate,
      isLoading
    });
  
    const handleRefresh = async () => {
      try {
        await loadTournamentData();
      } catch (err) {
        console.error('Error refreshing leaderboard:', err);
        setError('Failed to refresh leaderboard');
      }
    };
  
    // Show loading state when fetching initial data
    if (isLoading && leaderboard.length === 0) {
      return (
        <div className="flex justify-center py-8">
          <LoadingSpinner />
        </div>
      );
    }
  
    // Show empty state when no data is available
    if (!leaderboard || leaderboard.length === 0) {
      return (
        <EmptyState
          title="No Tournament Data"
          message="No tournament data available at the moment."
          icon={<Trophy className="w-12 h-12" />}
          action={
            <button 
              onClick={handleRefresh}
              className="text-blue-600 hover:text-blue-700 font-medium"
            >
              Refresh Data
            </button>
          }
        />
      );
    }
  
    // Render tournament leaderboard with data
    return (
      <TournamentLeaderboard 
        leaderboard={leaderboard}
        lastUpdate={lastUpdate}
        isLoading={false}
        onRefresh={handleRefresh}
        gameStatus={currentGame?.status}
      />
    );
  };

  const renderGameLeaderboard = () => {
    const handleRefresh = async () => {
      try {
        await loadGameLeaderboard();
      } catch (err) {
        console.error('Error refreshing game leaderboard:', err);
        setError('Failed to refresh game leaderboard');
      }
    };
  
    // Show loading spinner during initial data load
    if (isLoading && gameLeaderboard.length === 0) {
      return (
        <div className="flex justify-center py-8">
          <LoadingSpinner />
        </div>
      );
    }
  
    return (
      <GameLeaderboard 
        leaderboard={gameLeaderboard}
        isLoading={false}
        onRefresh={handleRefresh}
        isDeadlinePassed={isDeadlinePassed}
      />
    );
  };

  const renderSelectionForm = () => (
    <PlayerSelectionGrid
      availablePlayers={availablePlayers}
      onSubmit={handleSubmit}
      initialSelections={userSelections?.selections}
      selectedPlayers={selectedPlayers}
      setSelectedPlayers={setSelectedPlayers}
      isEditing={isEditing}
    />
  );

  const renderSelectionsContent = () => {
    console.log('Rendering selections content:', {
      user,
      currentGame,
      activeTab,
      leaderboard: leaderboard?.length,
      availablePlayers: availablePlayers?.length
    });
  
    // Success message component
    const successMessageComponent = successMessage && (
      <StateMessage
        type="success"
        message={successMessage}
        icon={<CheckCircle className="w-5 h-5" />}
      />
    );
  
    // Deadline passed state
    if (isDeadlinePassed) {
      return (
        <>
          {successMessageComponent}
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
            <h2 className="text-2xl font-bold mb-6">My Selections</h2>
            {userSelections?.selections?.length > 0 ? (
              <>
                <div className="space-y-4">
                  {userSelections.selections.map((selection, index) => (
                    <div key={selection._id} className="p-4 bg-blue-500/5 rounded-lg">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <div className="w-8 h-8 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-semibold mr-3">
                            {index + 1}
                          </div>
                          <div>
                            <div className="font-medium text-gray-900">{selection.name}</div>
                            <div className="text-sm text-gray-600">Odds: {selection.odds}/1</div>
                          </div>
                        </div>
                        <div className="text-right">
                          <span className={`text-xl font-bold ${
                            selection.tournamentScore === "CUT" ? "text-red-500" :
                            selection.tournamentScore?.startsWith("-") ? "text-green-600" : "text-red-600"
                          }`}>
                            {selection.tournamentScore || '-'}
                          </span>
                          <div className="text-sm text-gray-500">
                            Pos: {selection.tournamentPosition || '-'}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  
                  {userSelections.leagues && userSelections.leagues.length > 0 && (
                    <div className="border-t mt-6 pt-4">
                      <div className="flex items-center space-x-2 text-gray-600">
                        <Users className="w-4 h-4" />
                        <span>You're enrolled in {userSelections.leagues.join(', ')}</span>
                      </div>
                    </div>
                  )}
    
                  {/* Always show league enrollment option, even after deadline */}
                  <LeagueEnrollment userSelections={userSelections} />
                </div>
              </>
            ) : (
              <StateMessage
                type="warning"
                title="Deadline Passed"
                message="The deadline has passed and no selections were made for this tournament."
                icon={<Clock className="w-5 h-5" />}
              />
            )}
          </div>
        </>
      );
    }
  
    // Regular authenticated user view (before deadline)
    return (
      <>
        {successMessageComponent}
        {userSelections?.selections?.length > 0 && !isEditing ? (
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">My Selections</h2>
              <button
                onClick={handleEdit}
                className="flex items-center px-4 py-2 text-sm font-medium text-blue-600 
                  border border-blue-600 rounded-lg hover:bg-blue-50 transition-colors duration-300"
              >
                <Edit className="w-4 h-4 mr-2" />
                Edit Selections
              </button>
            </div>
            <div className="space-y-4">
              {userSelections.selections.map((selection, index) => (
                <div
                  key={selection._id || index}
                  className="p-4 bg-blue-500/5 rounded-lg"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="w-8 h-8 bg-blue-100 text-blue-600 rounded-full flex items-center justify-center font-semibold mr-3">
                        {index + 1}
                      </div>
                      <div>
                        <div className="font-medium text-gray-900">{selection.name}</div>
                        <div className="text-sm text-gray-600">Odds: {selection.odds}/1</div>
                      </div>
                    </div>
                    <div className="text-right text-sm text-gray-500">
                      Score: {selection.tournamentScore || '-'}<br />
                      Position: {selection.tournamentPosition || '-'}
                    </div>
                  </div>
                </div>
              ))}
              
              <div className="mt-6 p-4 bg-blue-500/5 rounded-lg">
                <div className="flex justify-between items-center">
                  <span className="text-sm font-medium text-gray-600">Combined Odds:</span>
                  <span className="text-lg font-bold">
                    {userSelections.selections.reduce((total, sel) => total + sel.odds, 0).toFixed(1)}/1
                  </span>
                </div>
              </div>
  
              <LeagueEnrollment userSelections={userSelections} />
            </div>
          </div>
        ) : (
          <div className="bg-gradient-to-br from-white to-blue-50 rounded-lg shadow-sm p-6">
            <h2 className="text-2xl font-bold mb-4">
              Make Your Selections
            </h2>
            {renderSelectionForm()}
          </div>
        )}
      </>
    );
  };
    
  if (isLoading) {
    console.log('=== Showing loading state ===');
    return (
      <div className="min-h-screen flex items-center justify-center">
        <LoadingSpinner size="large" fullHeight />
      </div>
    );
  }

  // Non-logged in user view
  if (!user) {
    return (
      <div className="min-h-screen bg-gray-50">
        <div className="sticky top-0 z-50 bg-white">
          {currentGame && renderHeader()}
        </div>
        
        <main className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            {!currentGame ? (
              <EmptyState
                title="No Active Tournament"
                message="There is no active tournament at the moment. Please check back later."
                icon={<Calendar className="w-12 h-12" />}
              />
            ) : (
              <LoginPrompt
                gameName={currentGame?.tournamentName || 'Golf Tournament'}
                gameDescription={`Join us to predict scores for ${currentGame?.name || 'this tournament'} and compete with other players.`}
                primaryButtonColor="blue"
              />
            )}
          </div>
        </main>
      </div>
    );
  }

  // Logged-in user view
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="sticky top-0 z-50 bg-white">
        {currentGame && renderHeader()}
        {renderTabs()}
      </div>
  
      <main className="container mx-auto px-4 py-8">
        <div className="max-w-6xl mx-auto space-y-6">
          {error && (
            <StateMessage
              type="error"
              message={error}
            />
          )}

          {!currentGame ? (
            <EmptyState
              title="No Active Tournament"
              message="There is no active tournament at the moment. Please check back later."
              icon={<Calendar className="w-12 h-12" />}
            />
          ) : (
            <>
              {activeTab === 'tournament' && renderTournamentLeaderboard()}
              {activeTab === 'selections' && renderSelectionsContent()}
              {activeTab === 'myleagues' && (
            <>
              {!user ? (
                <LoginPrompt
                  gameName={currentGame?.tournamentName || 'Golf Tournament'}
                  gameDescription="Log in to access your leagues and compete with other players."
                  primaryButtonColor="blue"
                />
              ) : isLoadingLeagues ? (
                <div className="flex justify-center py-8">
                  <LoadingSpinner />
                </div>
              ) : (
                <LeagueLeaderboard 
                  isDeadlinePassed={isDeadlinePassed}
                  userLeagues={userLeagues}
                  leagueLeaderboards={leagueLeaderboards}
                  isLoading={isLoadingLeagues}
                  onRefresh={loadLeagueData}
                  preloadedData={true}
                />
              )}
            </>
          )}
              {activeTab === 'leaderboard' && (
                <>
                  {!user ? (
                    <LoginPrompt
                      gameName={currentGame?.tournamentName || 'Golf Tournament'}
                      gameDescription="Log in to view the game leaderboard and track your progress."
                      primaryButtonColor="blue"
                    />
                  ) : (
                    renderGameLeaderboard()
                  )}
                </>
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};
    
export default GolfTournamentPage;